var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConfirmModalContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var opened = ref.opened;
var question = ref.question;
var okLabel = ref.okLabel;
var cancelLabel = ref.cancelLabel;
var reject = ref.reject;
var accept = ref.accept;
return [(opened)?_c('BModal',{attrs:{"visible":"","centered":"","modal-class":"confirm-box"},on:{"hidden":reject,"close":reject,"ok":accept},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Conferma necessaria")]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"button-reject btn btn-secondary",on:{"click":function($event){$event.preventDefault();return reject.apply(null, arguments)}}},[_vm._v(" "+_vm._s(cancelLabel)+" ")]),_c('button',{staticClass:"button-accept btn btn-primary",on:{"click":function($event){$event.preventDefault();return accept.apply(null, arguments)}}},[_vm._v(" "+_vm._s(okLabel)+" ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(question)+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }